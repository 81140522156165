<button mat-raised-button (click)="excel()">Download Excel</button>
<div class="header">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter by Anything">
  </mat-form-field>
</div>
<mat-table #table [dataSource]="dataSource">

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="members">
    <mat-header-cell *matHeaderCellDef> Members </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.member1 + ", " + element.member2 + ", " + element.member3 + ", " +
                                        element.member4 + ", " + element.member5 + ", " + element.member6}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="city">
    <mat-header-cell *matHeaderCellDef> City </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.city}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="email">
    <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="phone">
    <mat-header-cell *matHeaderCellDef> Phone </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.phone + ", " + element.altphone}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="songs">
    <mat-header-cell *matHeaderCellDef> Songs </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.songs + ", " + element.moresongs}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="other">
    <mat-header-cell *matHeaderCellDef> Other Links </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.otherlinks}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="fb">
    <mat-header-cell *matHeaderCellDef> Facebook Link </mat-header-cell>
    <mat-cell *matCellDef="let element"><a [href]="element.fblink"><button mat-raised-button>FB Link</button></a></mat-cell>
  </ng-container>
   <ng-container matColumnDef="createdAt.seconds">
    <mat-header-cell *matHeaderCellDef> Created At</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element['createdAt'].seconds}} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="sno">
    <mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
    <mat-cell *matCellDef="let i = index"> {{i+1}} </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="['sno', 'name', 'city', 'phone', 'email', 'members', 'songs', 'fb', 'other', 'createdAt.seconds']"></mat-header-row>
  <mat-row *matRowDef="let row; columns: ['sno', 'name', 'city', 'phone', 'email', 'members', 'songs', 'fb', 'other','createdAt.seconds'];"></mat-row>
</mat-table>

<mat-paginator #paginator
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20, 50]"
               [showFirstLastButtons]="true">
</mat-paginator>
