<mat-card class="profile">
  <app-go-back-component></app-go-back-component>
  <mat-card-title> Profile </mat-card-title>
  <mat-card-content>
    <div fxLayout="column">
      <div fxLayout="row">
        <span fxFlex="">Id:</span>
        <span fxFlex="">{{($localUser|async)?.uid}}</span>
      </div>
      <hr>
      <div fxLayout="row">
        <span fxFlex="">Username:</span>
        <span fxFlex="">{{($localUser|async)?.displayName}}</span>
      </div>
      <hr>
      <div fxLayout="row" fxLayoutAlign="center center">
        <span fxFlex="">Levels:</span>
        <span fxFlex="" fxLayout="column" fxLayoutAlign="center center">
          <span fxFlex=""
                *ngFor="let level of ($localUser|async)?.levelsCurrent" class="chip">
            {{ level }}
          </span>
        </span>
      </div>
      <hr>
      <div fxLayout="row" fxLayoutAlign="center center" *ngIf="($localUser|async)?.levelsRequested">
        <span fxFlex="">Requested:</span>
        <span fxFlex="" fxLayout="column" fxLayoutAlign="center center">
          <span fxFlex=""
                *ngFor="let level of ($localUser|async)?.levelsRequested" class="chip">
            {{ level }}
          </span>
        </span>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<mat-card class="profile">
  <mat-card-title> Request Level </mat-card-title>
  <mat-list>
    <h3 mat-subheader> Level Descriptions </h3>
    <mat-list-item *ngFor="let level of levels($localUser | async); let i=index">
      <h4>{{ level }}</h4> <p class="left-pad"> {{ descriptions[i] }} </p>
    </mat-list-item>
  </mat-list>
  <div class="profile">
    <form>
      <mat-select placeholder="Level available for request" id="selectionBox" [(ngModel)]="selectedVal" name="level" #levelSelection>
        <mat-option *ngFor="let level of levels($localUser | async)" [value]="level">
          {{ level }}
        </mat-option>
      </mat-select>
      <button mat-button type="submit" (click)="request()" [disabled]="requested">
        Request Level
      </button>
    </form>
  </div>
</mat-card>
