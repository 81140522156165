import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatTableDataSource } from '@angular/material/table';
import { JsontocsvService } from '../../utility/services/jsontocsv.service';
import { CATS } from '../../config/categories';

@Component({
  selector: 'app-mainsite-users',
  templateUrl: './mainsite-users.component.html',
  styleUrls: ['./mainsite-users.component.css'],
})
export class MainsiteUsersComponent implements OnInit {
  key;
  sliderChecked = false;
  dataSource: MatTableDataSource<any>;
  data;
  fields = ['name', 'city', 'mobile', 'email', 'college', 'events', 'fb'];
  fieldsToDisplay = [
    'name',
    'city',
    'mobile',
    'email',
    'college',
    'events',
    'fb',
    'payment',
  ];
  schema = `
  name;
  city;
  mobile,whatsapp;
  email;
  college;
  events;
  fb
  `;

  // nonRegisteredUsers(){
  //   this.afs.collection('fbusers', ref => ref.where("firstUpdate","==",false)).valueChanges().subscribe(
  //     (res) => {
  //       this.dataSource = new MatTableDataSource(
  //         this.data = Object.keys(res).map(k => {
  //           let temp: fbUser, r = res[k];
  //           if (r.registration || !r.campus) {
  //             temp = {
  //               name: r.name,
  //               email: r.email.value,
  //               events:  '',
  //               college: '',
  //               city: '',
  //               mobile: '',
  //               whatsapp: '',
  //               fb: '',
  //             };
  //             return temp;
  //           }
  //           return null;
  //         }).filter(val => val));
  //     }
  //   );
  // }

  constructor(private afs: AngularFirestore, private j2c: JsontocsvService) {}

  ngOnInit() {
    this.afs
      .collection('fbusers', (ref) => ref.where('firstUpdate', '==', true))
      .valueChanges()
      .subscribe((res) => this.flattenUsers(res));
  }

  paidUsers() {
    const newdat = this.data;
    this.dataSource = new MatTableDataSource(
      newdat.filter((val) => val.payment)
    );
    this.setFilter();
  }

  allUsers() {
    this.dataSource = new MatTableDataSource(this.data);
    this.setFilter();
  }

  setFilter() {
    this.dataSource.filterPredicate = function (data, filter: string): boolean {
      const str = filter.split('$');
      let a = data.name;
      a = a ? a.toLowerCase() : '';
      let b = data.college;
      b = b ? b.toLowerCase() : '';
      let c = data.events;
      c = c ? c.toLowerCase() : '';
      return a.includes(str[0]) && b.includes(str[1]) && c.includes(str[2]);
    };
  }

  flattenUsers(res) {
    this.dataSource = new MatTableDataSource(
      (this.data = Object.keys(res)
        .map((k) => {
          let temp: FbUser
            const r = res[k];
          if (r.registration || !r.campus) {
            temp = {
              name: r.name,
              email: r.email.value,
              events: r.registration
                ? this.getEvents(r.registration.events)
                : 'NA',
              college: r.personal.college,
              city: r.personal.city,
              mobile: r.personal.phoneNumber,
              whatsapp: r.personal.whatsAppNumber,
              fb: r.facebook ? r.facebook.facebookLink : 'NA',
              payment: r.payment,
              uid: r.uid,
            };
            return temp;
          }
          return null;
        })
        .filter((val) => val))
    );
    this.setFilter();
  }

  getEvents(evs): string {
    let str = '';
    for (const prop in evs) {
      if (evs[prop]) {
        str = str + ' ' + CATS[prop] + '/' + prop.toString();
      }
    }
    return str;
  }

  excel() {
    this.j2c.excel(this.data);
  }
}

export interface FbUser {
  name: string;
  college: string;
  mobile: string;
  whatsapp: string;
  city: string;
  email: string;
  events: string;
  fb: string;
  payment: boolean;
  uid: string;
}
