import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { CKEditorModule } from "ng2-ckeditor";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";

// Services
import { JsontocsvService } from "./utility/services/jsontocsv.service";

// features
import * as fromAresUsers from "./ares-users";
import * as fromUtils from "./utility";

// Components
import { AppComponent } from "./components";
import { ConfirmDialogComponent } from "@utils";
// import {EditUserDialogComponent} from '@utils';
import { MaterialTableComponent } from "@utils";

// Modules
import { AppRoutingModule } from "./app-routing.module";
import { environment } from "@environments/environment";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AresMaterialModule } from "./modules/material.module";
import { SynchronicityComponent } from "./roadtrips/components/synchronicity/synchronicity.component";
import { ComiconComponent } from "./roadtrips/components/comicon/comicon.component";
import { DjwarComponent } from "./roadtrips/components/djwar/djwar.component";
import { MarkdownModule } from "ngx-markdown";
import { ContentManagerComponent } from "./data_layer/components/content-manager/content-manager.component";
import {
  ContentDataManagerComponent,
  EditorComponent,
  PreviewFileComponent,
} from "./data_layer/components/content-data-manager/content-data-manager.component";
import { CalistComponent } from "./ca/components/calist/calist.component";
import { ContentPermitComponent } from "./data_layer/components/content-permit/content-permit.component";
import { IdeasComponent } from "./ca/components/ideas/ideas.component";
import { MainsiteUsersComponent } from "./data_layer/mainsite-users/mainsite-users.component";
import { MunUsersComponent } from "./data_layer/mun-users/mun-users.component";
import { PaymentsComponent } from "./payments/payments.component";
import { EditUserDialogComponent } from "./ca/edit-user-dialog";
import { JunoonComponent } from "./roadtrips/components/junoon/junoon.component";
import { RapComponent } from "./roadtrips/components/rap/rap.component";
import { AssociatesComponent } from "./data_layer/associates/associates.component";
import { OnlineedmComponent } from "./onlineedm/onlineedm.component";
import { ProdigyComponent } from "./prodigy/prodigy.component";
import { SubmissionsComponent } from "./prodigy/submissions/submissions.component";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { BeatboxingComponent } from "./roadtrips/components/beatboxing/beatboxing.component";

@NgModule({
  entryComponents: [
    ConfirmDialogComponent,
    EditUserDialogComponent,
    MaterialTableComponent,
    PreviewFileComponent,
    EditorComponent,
  ],
  declarations: [
    AppComponent,
    fromAresUsers.components,
    fromUtils.declarations,
    SynchronicityComponent,
    ComiconComponent,
    DjwarComponent,
    ContentManagerComponent,
    ContentDataManagerComponent,
    PreviewFileComponent,
    EditorComponent,
    CalistComponent,
    ContentPermitComponent,
    IdeasComponent,
    MainsiteUsersComponent,
    MunUsersComponent,
    PaymentsComponent,
    EditUserDialogComponent,
    JunoonComponent,
    RapComponent,
    AssociatesComponent,
    OnlineedmComponent,
    ProdigyComponent,
    SubmissionsComponent,
    BeatboxingComponent,
  ],
  imports: [
    // core modules
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    HttpClientModule,
    ClipboardModule,

    // shared modules
    FlexLayoutModule,
    ReactiveFormsModule,
    MarkdownModule.forRoot(),
    AppRoutingModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AresMaterialModule,
    CKEditorModule,

    // firebase modules
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
  ],
  providers: [
    fromAresUsers.services,
    fromAresUsers.guards,
    fromUtils.services,
    JsontocsvService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
