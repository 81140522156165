import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AngularFirestore } from '@angular/fire/firestore';
import { Funcs } from '@utils';

@Component({
  selector: 'app-edit-user-dialog',
  templateUrl: './edit-user-dialog.component.html',
  styleUrls: ['./edit-user-dialog.component.css'],
})
export class EditUserDialogComponent implements OnInit {
  public title: string;

  totalPoints: number;
  ideaPoints: number;
  otherPoints: number;
  likes: number;
  shares: number;

  constructor(
    public dialogRef: MatDialogRef<EditUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private afs: AngularFirestore,
    private functions: Funcs
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.title = this.data.name;
      this.totalPoints = this.data.campus.totalPoints;
      this.ideaPoints = this.data.campus.ideaPoints;
      this.otherPoints = this.data.campus.otherPoints;
      this.likes = this.data.campus.likes;
      this.shares = this.data.campus.shares;
    }
  }

  update() {
    const temp = {
      'campus.totalPoints': this.totalPoints,
      'campus.ideaPoints': this.ideaPoints,
      'campus.otherPoints': this.otherPoints,
      likes: this.likes,
      shares: this.shares,
    };
    this.afs
      .doc('fbusers/' + this.data.uid)
      .update(temp)
      .then(() => {
        this.dialogRef.close('Updated user data');
      })
      .catch((err) => {
        this.functions.handleError(err.message);
      });
  }

  close() {
    this.dialogRef.close();
  }
}
