<div fxLayoutAlign="center center">
  <mat-slide-toggle #sliders (change)="(sliders.checked)?paidUsers():allUsers()" [(ngModel)]="sliderChecked"><h3>Show only paid users</h3>
  </mat-slide-toggle>
</div>
<app-material-table
  [fields]="fields"
  [schema]="schema"
  [data]="data"
  [fieldsToDisplay]="fieldsToDisplay"
  [filters]="['name','college','event']"
  [dataSource]="dataSource">
</app-material-table>
