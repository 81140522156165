import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { JsontocsvService } from '../../../utility/services/jsontocsv.service'
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'app-rap',
  templateUrl: './rap.component.html',
  styleUrls: ['./rap.component.css']
})
export class RapComponent implements OnInit {
  dataSource;
  data;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(private afs: AngularFirestore, private j2c: JsontocsvService) { }

  ngOnInit() {
    this.afs.collection('rap').valueChanges().subscribe(
      (res) => {
        this.data = res;
        this.dataSource = new MatTableDataSource(Object.keys(res).map(k => res[k]));
        this.dataSource.paginator = this.paginator;
      }
    );
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  excel() {
    this.j2c.excel(this.data);
  }

}


