import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { LoggedUserService } from '../../ares-users/services/logged-user';
import { LocalUser } from 'app/ares-users/models/localUser';
import { map } from 'rxjs/internal/operators';
import { Content } from '../../ares-users/models/content';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-associates',
  templateUrl: './associates.component.html',
  styleUrls: ['./associates.component.css']
})
export class AssociatesComponent implements OnInit {
  dataSource;
  cols = ['sno', 'cell', 'name', 'company', 'email', 'phone', 'message'];
  constructor(private afs: AngularFirestore, private serv: LoggedUserService) { }

  ngOnInit() {
    this.serv.currentUser.pipe(
      map((user: LocalUser) => {
        this.afs.collection('associates').valueChanges().subscribe((val: any) => {
          const res = val.filter((doc) => user.levelsCurrent.includes(doc.cell) || user.levelsCurrent.includes('admin'));
          this.dataSource = new MatTableDataSource(res);
          // this.dataSource = new MatTableDataSource(Object.keys(res).map(k => res[k]))
        })
      })
    ).subscribe();
  }

}
