import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatTableDataSource } from '@angular/material/table';
import { HttpClient } from '@angular/common/http';

// export const httpOptions = 'eyJhbGciOiJIUzUxMiJ9.eyJST0xFIjoiUk9MRV9VU0VSIiwic3ViIjoiaG9zcGl0YWxpdHlAYW50YXJhZ25pLmluIiwiYXVkaWVuY2UiOiJ3ZWIiLCJjcmVhdGVkIjoxNTM3NzkyMjQxMjk0LCJVU0VSX0lEIjoyMTU1NywiZXhwIjoxNTQ1NTY4MjQxfQ.lhjSpfkewtEuHkawhZafkBuq3tO1XGG-4MlWxBaj6GQC2CK6Qu1OTaIm1kzROblOM3tnHm8ljeTxgfZKbAM-vg';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css'],
})
export class PaymentsComponent implements OnInit {
  // res;
  // url="https://www.townscript.com/api/registration/getRegisteredUsers?eventCode=pune-nationals-antaragni18-210013";
  //  authHeader(): HttpHeaders {
  //     let Headers: HttpHeaders;
  //     Headers = new HttpHeaders().set('Authorization', httpOptions) );
  //     return Headers;
  //   }

  dataSource: MatTableDataSource<Item>;
  data: Item[];
  fields = [
    'ids',
    'username',
    'college',
    'number',
    'email',
    'total',
    'invalid',
  ];
  fieldsToDisplay = [
    'sno',
    'ids',
    'username',
    'college',
    'number',
    'email',
    'total',
  ];
  schema = `
  ids;
  username;
  college;
  number;
  email;
  total;
  invalid
  `;

  // showData() {
  // console.log("in showdata fn");
  // this.getData()
  //   .subscribe((data: any) => {this.res= data;
  //   console.log(data);});
  // }

  // getData() {
  // console.log("in getdata");
  // return this.http.get(this.url,{headers: this.authHeader()});
  // }

  constructor(private afs: AngularFirestore, private http: HttpClient) {}

  ngOnInit() {
    this.afs
      .collection('payments')
      .valueChanges()
      .subscribe((res: any) => {
        this.dataSource = new MatTableDataSource(
          (this.data = Object.keys(res)
            .map((k) => {
              if (!res[k].name) {
                let temp: Item;
                const r = JSON.parse(res[k].data);
                const ids: Array<string> = r['customAnswer90054']
                  .trim()
                  .split(/\s+/);
                const idslen = ids.length;
                let istrue = !(
                  r['totalTicketAmount'] < 1800 * idslen ||
                  r['totalTicketAmount'] > 2100 * idslen
                );
                // console.log(ids);
                if (
                  ids.filter((val) => val.length !== 8 || val[7] === 'X').length
                ) {
                  istrue = false;
                }
                const t = {};
                const answerList = r['answerList'].map(
                  (val: { [x: string]: any }) => {
                    const field = val['question'];
                    t[field] = val['answer'];
                    return t;
                  }
                );
                const tstamp = r['registrationTimestamp'].split(' '),
                  d = tstamp[0].split('-');
                const fdate = new Date(`${d[1]}-${d[0]}-${d[2]} ${tstamp[1]}`);
                const dates = (fdate.getTime() / 1000).toString();
                console.log(dates, r['registrationTimestamp']);
                temp = {
                  sno: dates,
                  ids: r['customAnswer90054'],
                  username: r['userName'],
                  college: answerList[0]['College Name'],
                  number: answerList[1]['Contact Number'],
                  email: r['userEmailId'],
                  total: r['totalTicketAmount'],
                  invalid: !istrue,
                };

                // for (let id in ids) {
                //
                // }
                // console.log(answerList, r['answerList']);
                return temp;
              }
            })
            .filter((val) => val))
        );
      });
  }
}

export interface Item {
  sno: string;
  username: string;
  email: string;
  total: string;
  invalid: boolean;
  ids: string;
  college: string;
  number: number;
}
