
<mat-card-title>{{ title }}</mat-card-title>
<mat-card-content>
  <div fxLayout="column" fxLayoutWrap="wrap" fxLayoutGap="10px" fxLayoutAlign="center center">

    <mat-form-field>
      <span>Total Points</span>
        <input matInput type="number" [(ngModel)]="totalPoints" placeholder="Total Points" [value]="this.data?.campus?.totalPoints">
    </mat-form-field>
    <mat-form-field>
      <span>Idea Points</span>
        <input matInput type="number" [(ngModel)]="ideaPoints" placeholder="Idea Points" [value]="this.data?.campus?.ideaPoints">
    </mat-form-field>
    <mat-form-field>
      <span>Other Points</span>
      <input matInput type="number" [(ngModel)]="otherPoints" placeholder="Other Points" [value]="this.data?.campus?.otherPoints">
    </mat-form-field>
    <mat-form-field>
      <span>Likes</span>
      <input matInput type="number" [(ngModel)]="likes" placeholder="Likes" [value]="this.data?.campus?.likes">
    </mat-form-field>
    <mat-form-field>
      <span>Shares</span>
      <input matInput type="number" [(ngModel)]="shares" placeholder="Shares" [value]="this.data?.campus?.shares">
    </mat-form-field>
    
  </div>
</mat-card-content>
<mat-dialog-actions>
    <button mat-raised-button (click)="update()">Update</button>
    <button mat-button (click)="close()">Close</button>
</mat-dialog-actions>
