import {Component, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {environment} from '@environments/environment';
import {LoggedUserService} from '../../../ares-users/services';
import {Content} from '@models/content';
import {Funcs} from '@utils';

@Component({
  selector: 'app-content-permit',
  templateUrl: './content-permit.component.html',
  styleUrls: ['./content-permit.component.css']
})
export class ContentPermitComponent implements OnInit {
  data;
  AllLevels;

  constructor(private afs: AngularFirestore, private login: LoggedUserService, private fun: Funcs) {
    this.AllLevels = this.login.allLevels.map(val => val.name);
  }

  ngOnInit() {
    const source = this.afs.collection(environment.contentCollectionName);
    this.data = source.valueChanges();
  }

  BackupAll() {
    this.afs.collection(environment.contentCollectionName, ref => ref.orderBy('name')).valueChanges().subscribe(
      (res: Array<Content>) => {
        for (const doc of res) {
          this.afs.doc(`${environment.contentCollectionName}_backup/` + doc.name).set(doc).catch(err => this.fun.handleError(err.message));
        }
      }
    )
  }

  RestoreAll() {
    this.afs.collection(`${environment.contentCollectionName}_backup/`, ref => ref.orderBy('name')).valueChanges().subscribe(
      (res: Array<Content>) => {
        for (const doc of res) {
          this.afs.doc(environment.contentCollectionName + '/' + doc.name).set(doc).catch(err => this.fun.handleError(err.message));
        }
      }
    )
  }

  Backup(docName: string) {
    this.afs.doc(environment.contentCollectionName + '/' + docName).valueChanges().subscribe(
      (res: Content) => this.afs.doc(`${environment.contentCollectionName}_backup/` + docName)
        .set(res)
        .catch(err => this.fun.handleError(err.message))
    )
  }

  Restore(docName: string) {
    this.afs.doc(`${environment.contentCollectionName}_backup/` + docName).valueChanges().subscribe(
      (res: Content) => this.afs.doc(environment.contentCollectionName + '/' + docName)
        .set(res)
        .catch(err => this.fun.handleError(err.message))
    )
  }

  Delete(docName: string) {
    this.fun.confirmDialog('Delete Row?', 'Are you sure you want to delete?')
      .subscribe((flag: boolean) => {
        if (flag) {
          this.afs.doc(`${environment.contentCollectionName}_backup/` + docName)
            .delete()
            .catch(err => this.fun.handleError(err.message));
          this.afs.doc(`${environment.contentCollectionName}/` + docName)
            .delete()
            .catch(err => this.fun.handleError(err.message));
        }
      });
  }

  onSelect(level: string, name: string) {
    this.afs.doc(environment.contentCollectionName + '/' + name)
      .update({level: level})
      .catch(err => this.fun.handleError(err.message))
  }

}
