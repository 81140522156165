<div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1vh">
  <div fxLayout="row" fxLayoutGap="5vh" fxLayoutAlign="space-between center" class="rows">
    <h3>
      doc name
    </h3>
    <button mat-raised-button (click)="BackupAll()">BackUp All</button>
    <button mat-raised-button (click)="RestoreAll()">Restore All</button>
    <h4>Permitted Level</h4>
    <h5>Delete</h5>
  </div>
  <div *ngFor="let doc of data | async" fxLayout="row" fxLayoutGap="5vh" fxLayoutAlign="space-between center"
       class="rows">
    <h3>
      {{doc?.name}}
    </h3>
    <button mat-raised-button (click)="Backup(doc?.name)">BackUp</button>
    <button mat-raised-button (click)="Restore(doc?.name)">Restore</button>
    <mat-form-field>
      <mat-select placeholder="Set Level" [value]="doc?.level" #levelSelection id="selection"
                  (selectionChange)="onSelect(levelSelection.value,doc.name)">
        <mat-option *ngFor="let level of AllLevels" [value]="level">
          {{ level }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-raised-button (click)="Delete(doc.name)">Delete</button>
  </div>
</div>
