import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatTableDataSource } from '@angular/material/table';
import { JsontocsvService } from '../utility/services/jsontocsv.service';
import { MatDialog } from '@angular/material/dialog';
import { SubmissionsComponent } from './submissions/submissions.component';
import { MatPaginator } from '@angular/material/paginator';
import { stdout } from 'process';

@Component({
  selector: 'app-prodigy',
  templateUrl: './prodigy.component.html',
  styleUrls: ['./prodigy.component.css'],
})
export class ProdigyComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataToPdf;
  dataSource;
  tempData = [];
  constructor(
    private afs: AngularFirestore,
    private j2c: JsontocsvService,
    private dialog: MatDialog
  ) {}

  getDate(value: number) {
    const d = new Date(value);
    // console.log(new Date(value).getDate(),value);
    return d.toLocaleString(undefined, {timeZone: 'Asia/Kolkata'});
  }

  ngOnInit(): void { 

    this.afs.collection('events').get();
    this.afs
      .collection('events', (ref) => ref.orderBy('createdAt','desc'))
      .valueChanges()
      .subscribe((res: any) => {
        this.tempData = res;
        res.forEach((stu) => {
          if(stu.createdAt!=null){
            const createdAt = stu.createdAt;
              const createKey = "ANT" + createdAt.toString().substring(4) + String.fromCharCode(Math.floor(createdAt/1000000000%100)%26+65);
            stu.createKey = createKey;
          }
          if(stu.online!=null){
          stu.comps = Object.keys(stu.online).join(', ');
          stu.tempCollegeName = stu.personal.college;
          stu.tempPhone = stu.personal.phoneNumber;
          stu.tempWhatsapp = stu.personal.whatsAppNumber;
          stu.tempCity = stu.personal.city;
          stu.tempZip = stu.personal.zipcode;
          stu.tempGender = stu.personal.gender;
          stu.g = stu.personal.postalAddress;


          }
        });
        
        this.dataToPdf = res
          .map((e) => {
            
            if(e.createdAt!=null){
              const createdAt = e.createdAt;
                const createKey = "ANT" + createdAt.toString().substring(4) + String.fromCharCode(Math.floor(createdAt/1000000000%100)%26+65);
              e.createKey = createKey;
            }

            if(e.online!=null){
            const keys = Object.keys(e.online);
            // e.links = keys.map((k) => [k, e.online[k]].join(':  ')).join('\n');
            e.links = keys.map((k) => {
              if(!e.online[k]) {
                return;
              }
              return {
                key: k,
                value: e.online[k]
              };
            });
            delete e.online;
            e.links = e.links.filter((f) => f);
            }
            return e;
          });
          
        this.dataSource = new MatTableDataSource(this.tempData);
        this.dataSource.paginator = this.paginator;
      });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  excel() {
    this.j2c.excel(this.dataToPdf);
  }

  view(online) { 
    console.log("online", online);
    this.dialog.open(SubmissionsComponent, {
      data:  online,
    });
  }
}
