<button mat-raised-button (click)="excel()">Download Excel</button>
<div class="header">
  <mat-form-field *ngIf="!filters?.length">
    <input matInput (keyup)="applylegacyFilter($event.target.value)" placeholder="Filter by Anything">
  </mat-form-field>
  <ng-container *ngIf="filters?.length">
    <div *ngFor="let filter of filters; let i=index" fxLayout="row wrap" fxLayoutAlign="center center"
         fxLayoutGap="30px">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value, i)" [placeholder]="'Filter by '+ filter">
      </mat-form-field>
    </div>
  </ng-container>
</div>
<div class="tablewrapper">
  <mat-table #table [dataSource]="dataSource" matSort>

    <ng-container *ngFor="let field of fields; let i = index" [matColumnDef]="field">
      <mat-header-cell *matHeaderCellDef> {{field}}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{getVal(element,i)}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="payment">
      <mat-header-cell *matHeaderCellDef> Payment</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-raised-button (click)="togglepayment(element.uid, element.payment)">
          {{element.payment?"Unverify":"Verify"}}
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="sno">
      <mat-header-cell *matHeaderCellDef mat-sort-header> No.</mat-header-cell>
      <mat-cell *matCellDef="let i = index; let element"> {{i+' '+element.sno}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="fieldsToDisplay"></mat-header-row>
    <mat-row *matRowDef="let row; columns: fieldsToDisplay;" [class.invalid]="row.invalid"></mat-row>
  </mat-table>
</div>

<mat-paginator #paginator
               [pageSize]="20"
               [pageSizeOptions]="[10, 25, 50, 1000]"
               [showFirstLastButtons]="true">
</mat-paginator>
