import { Component, OnInit, ViewChild } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { JsontocsvService } from "../../../utility/services/jsontocsv.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "app-comicon",
  templateUrl: "./comicon.component.html",
  styleUrls: ["./comicon.component.css"],
})
export class ComiconComponent implements OnInit {
  dataSource;
  data;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(private afs: AngularFirestore, private j2c: JsontocsvService) {}

  ngOnInit() {
    this.afs
      .collection("comicon")
      .valueChanges()
      .subscribe((res) => {
        this.data = res;
        this.dataSource = new MatTableDataSource(
          Object.keys(res).map((k) => {
            console.log(res[k]);
            res[k]["createdAt"].seconds = new Date(
              res[k]["createdAt"].seconds * 1000
            ).toLocaleString();
            return res[k];
          })
        );
        this.dataSource.paginator = this.paginator;
      });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  excel() {
    this.j2c.excel(this.data);
  }
}
