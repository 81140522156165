import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoggedUserService } from '../../services';
import { Funcs } from '@utils';

@Component({
  selector: 'app-login-component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  submitted = false;

  constructor(
    private loginService: LoggedUserService,
    private functions: Funcs,
    private router: Router
  ) {}

  onSubmit = (username: string, password: string): void => {
    this.submitted = true;
    this.loginService
      .signIn(username, password)
      .then(() => {
        console.log('logged in');
        this.router.navigate(['dashboard']);
      })
      .catch((err) => {
        this.submitted = false;
        this.functions.handleError(err.message);
      });
  };
}
