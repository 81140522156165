import {FileUploadComponent} from './file-upload';
import {DropBoxDirective} from './file-upload';
import {FileSizePipe} from './file-upload';
import {ConfirmDialogComponent} from './confirm-dialog';
import {GoBackComponent} from './go-back';
import {Funcs} from './functions';
import {MaterialTableComponent} from './material-table/material-table.component'

export const declarations: any[] = [
  FileUploadComponent,
  FileSizePipe,
  DropBoxDirective,
  FileSizePipe,
  ConfirmDialogComponent,
  GoBackComponent,
  MaterialTableComponent,
];

export const services: any[] = [Funcs];

export * from './confirm-dialog';
export * from './file-upload';
export * from './go-back';
export * from './functions';
export * from './material-table/material-table.component'
