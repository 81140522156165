export const CATS = {
  'Junoon': 'Junoon',
  'Naqaab': 'art',
  'Costume Designing': 'art',
  'Paint the spot': 'art',
  'Quilling and Origami': 'art',
  'Battle of Art': 'art',
  'Modern Art': 'art',
  'Comic-Kaun': 'comic',
  'Estampie': 'dance',
  'Jitterbug': 'dance',
  'Nrityangana': 'dance',
  'Roots': 'dance',
  'Tour De Force': 'dance',
  'Nukkad Natak': 'drams',
  'Stage Play': 'drams',
  'Mimika': 'drams',
  'Poetry Slam': 'ele',
  'Word Games': 'ele',
  'Just a Minute': 'ele',
  'Creative Writing': 'ele',
  'Parliamentary Debate': 'ele',
  'TeeVeeCee (Ad making)': 'films',
  'Kahani (Short Film)': 'films',
  'Honest Trailers': 'films',
  'Kavyanjali': 'hle',
  'Drishtikon': 'hle',
  'Kirdar': 'hle',
  'Aamne-Samne': 'hle',
  'Mayanagri': 'hle',
  'Mr. and Mrs Antaragni': 'mrmrsantaragni',
  'Sangam': 'musicals',
  'A Capella': 'musicals',
  'Antaragni Idol': 'musicals',
  'Pair On Stage': 'musicals',
  'Unplugged': 'musicals',
  'Symphony': 'musicals',
  'Depiction(Photostory)': 'photography',
  'Bhavna': 'photography',
  'IRIS(Aperture)': 'photography',
  'Movie Quiz': 'quiz',
  'General Quiz': 'quiz',
  'Sports Quiz': 'quiz',
  'TLC': 'quiz',
  'Mela Quiz': 'quiz',
  'Ritambhara': 'ritambhara',
  'Disenado': 'ritambhara',
  'Impressionate': 'ritambhara'
};
