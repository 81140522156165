<div fxLayout="column" fxLayoutAlign="center center" class="container">
  <button mat-raised-button (click)="seeChecked()">See Sorted Ideas</button><br>
  <button mat-raised-button (click)="setData('name')">Sort by name</button>
  <mat-card fxLayout="row" fxLayoutAlign="space-between center" *ngFor="let idea of Ideadata | async"
            [class.red]="idea?.status == 0" [class.green]="idea?.status == 1">
    <div>{{idea?.name}}</div>
    <div>{{idea?.idea?.subject}}</div>
    <button mat-raised-button (click)="OpenDialog(idea?.idea?.subject, idea?.idea?.message, idea?.id, idea?.uid)"> Open</button>
  </mat-card>
</div>
