<button mat-raised-button (click)="fetchPosts()"> Refresh Page posts </button>
<button mat-raised-button (click)="excel()"> Download Excel </button>
<div class="header">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter by Anything">
    </mat-form-field>
  </div>
  <mat-table #table [dataSource]="dataSource">

    <ng-container matColumnDef="sno">
        <mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
        <mat-cell *matCellDef="let i = index"> {{i+1}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="city">
        <mat-header-cell *matHeaderCellDef> City </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.personal.city}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.email.value}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="phone">
      <mat-header-cell *matHeaderCellDef> Phone </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.personal.phoneNumber + ", " + element.personal.whatsAppNumber}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="college">
      <mat-header-cell *matHeaderCellDef> College </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.personal.college}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="fb">
      <mat-header-cell *matHeaderCellDef> Facebook Profile </mat-header-cell>
      <mat-cell *matCellDef="let element">
          <a [href]="element.facebook.facebookLink" target="_blank">
            <button mat-raised-button>FB</button>
          </a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="created">
      <mat-header-cell *matHeaderCellDef> Created At </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ getDate(element.createdAt) }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef > Edit / Delete </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-button (click)="openDialog(element)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-button (click)="deleteUser(element.uid)">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-card>

    </mat-card>

    <mat-header-row *matHeaderRowDef="['sno', 'name', 'city', 'phone', 'email', 'college', 'fb', 'edit','created']"></mat-header-row>
    <mat-row *matRowDef="let row; columns: ['sno', 'name', 'city', 'phone', 'email', 'college', 'fb', 'edit','created']"></mat-row>
  </mat-table>

