// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCDqG_OzK9b-YaQ0rpzoOxkDP11U-X9zmE',
    authDomain: 'espalda-fin.firebaseapp.com',
    databaseURL: 'https://espalda-fin.firebaseio.com',
    projectId: 'espalda-fin',
    storageBucket: 'espalda-fin.appspot.com',
    messagingSenderId: '74163403274'
  },
  contentCollectionName: '_contents_'
};
