<mat-table #table [dataSource]="dataSource">

    <ng-container *ngFor="let col of cols | slice:1" [matColumnDef]="col">
        <mat-header-cell *matHeaderCellDef> {{col}} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element[col]}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="sno">
        <mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
        <mat-cell *matCellDef="let i = index"> {{i+1}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="cols"></mat-header-row>
    <mat-row *matRowDef="let row; columns: cols;"></mat-row>
</mat-table>
