import { JunoonComponent } from "./roadtrips/components/junoon/junoon.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CanDeactivateGuard } from "./deactivate.guard";
import { CalistComponent } from "./ca/components/calist/calist.component";
// Components
import { aresUsersRoutes } from "./ares-users";
import { LevelGuard, LocalUserGuard } from "./ares-users/guards";
import { MainsiteUsersComponent } from "./data_layer/mainsite-users/mainsite-users.component";
import { MunUsersComponent } from "./data_layer/mun-users/mun-users.component";
import { DjwarComponent } from "./roadtrips/components/djwar/djwar.component";
import { ContentManagerComponent } from "./data_layer/components/content-manager";
import { ContentDataManagerComponent } from "./data_layer/components/content-data-manager";
import { SynchronicityComponent } from "./roadtrips/components/synchronicity/synchronicity.component";
import { ComiconComponent } from "./roadtrips/components/comicon/comicon.component";
import { ContentPermitComponent } from "./data_layer/components/content-permit/content-permit.component";
import { IdeasComponent } from "./ca/components/ideas/ideas.component";
import { PaymentsComponent } from "./payments/payments.component";
import { RapComponent } from "./roadtrips/components/rap/rap.component";
import { AssociatesComponent } from "./data_layer/associates/associates.component";
import { OnlineedmComponent } from "./onlineedm/onlineedm.component";
import { ProdigyComponent } from "./prodigy/prodigy.component";
import { BeatboxingComponent } from "./roadtrips/components/beatboxing/beatboxing.component";

const routes: Routes = [
  { path: "", redirectTo: "dashboard", pathMatch: "full" },
  {
    path: "calist",
    component: CalistComponent,
    canActivate: [LevelGuard],
    data: { level: "hospitality" },
  },
  {
    path: "mainusers",
    component: MainsiteUsersComponent,
    canActivate: [LevelGuard],
    data: { level: "hospitality" },
  },
  {
    path: "munusers",
    component: MunUsersComponent,
    canActivate: [LevelGuard],
    data: { level: "mun" },
  },
  {
    path: "synchronicity",
    component: SynchronicityComponent,
    canActivate: [LevelGuard],
    data: { level: "synchronicity" },
  },
  {
    path: "comicon",
    component: ComiconComponent,
    canActivate: [LevelGuard],
    data: { level: "comicon" },
  },
  {
    path: "junoon",
    component: JunoonComponent,
    canActivate: [LevelGuard],
    data: { level: "junoon" },
  },
  {
    path: "rap",
    component: RapComponent,
    canActivate: [LevelGuard],
    data: { level: "rap" },
  },
  {
    path: "djwar",
    component: DjwarComponent,
    canActivate: [LevelGuard],
    data: { level: "djwar" },
  },
  {
    path: "beatboxing",
    component: BeatboxingComponent,
    canActivate: [LevelGuard],
    data: { level: "beatboxing" },
  },
  {
    path: "associates",
    component: AssociatesComponent,
    canActivate: [LocalUserGuard],
  },
  {
    path: "manage",
    component: ContentManagerComponent,
    canActivate: [LocalUserGuard],
  },
  {
    path: "manage/:name",
    component: ContentDataManagerComponent,
    canActivate: [LocalUserGuard],
  },
  {
    path: "permissions",
    component: ContentPermitComponent,
    canActivate: [LevelGuard],
    data: { level: "admin" },
  },
  {
    path: "ideas",
    component: IdeasComponent,
    canActivate: [LevelGuard],
    data: { level: "hospitality" },
  },
  {
    path: "payments",
    component: PaymentsComponent,
    data: { level: "hospitality" },
  },
  {
    path: "onlineedm",
    component: OnlineedmComponent,
    canActivate: [LevelGuard],
    data: { level: "hospitality" },
  },
  {
    path: "online",
    component: ProdigyComponent,
    canActivate: [LevelGuard],
    data: { level: "events" },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    RouterModule.forChild(aresUsersRoutes),
  ],
  exports: [RouterModule],
  providers: [CanDeactivateGuard],
})
export class AppRoutingModule {}
