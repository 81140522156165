<mat-card class="users">
  <app-go-back-component></app-go-back-component>
  <mat-card-title> Users </mat-card-title>
  <mat-card-content>
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="center center">
        <span fxFlex=""> <strong>Username</strong> </span>
        <span fxFlex="" style="text-align: center"> <strong>Levels</strong> </span>
        <span fxFlex="" style="text-align: center"> <strong>Requested</strong> </span>
        <span fxFlex=""> <strong>Promotion</strong> </span>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" *ngFor="let localUser of localUsers">
        <span fxFlex=""> {{localUser?.email }} </span>
        <span fxFlex="" fxLayout="column" fxLayoutAlign="center center">
          <div fxFlex="" class="chip" *ngFor="let level of localUser?.levelsCurrent">
            {{ level }}
            <button mat-icon-button (click)="deny(localUser, level, 'levelsCurrent')">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </span>
        <span fxFlex="" fxLayout="column" fxLayoutAlign="center center">
          <div fxFlex="" class="chip2" *ngFor="let level of localUser?.levelsRequested">
            <button mat-icon-button (click)="approve(localUser, level)">
              <mat-icon>check</mat-icon>
            </button>
            {{ level }}
            <button mat-icon-button (click)="deny(localUser, level, 'levelsRequested')">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </span>
        <span fxFlex="">
         <input mat-input placeholder="new level" #inlev type="text" label="level" (keydown.enter)="approve(localUser, inlev.value)">
        </span>
      </div>
    </div>
  </mat-card-content>
</mat-card>
