export { ConfirmDialogComponent } from '../utility/confirm-dialog/confirm-dialog.component';
export { AppComponent } from './app-component/app.component';
export { DashboardComponent } from '../ares-users/components/dashboard/dashboard.component';
export { LoginComponent } from '../ares-users/components/login/login.component';
export { GoBackComponent } from '../utility/go-back/go-back.component';
export { NavbarComponent } from '../ares-users/components/navbar/navbar.component';
export { ProfileComponent } from '../ares-users/components/profile/profile.component';
export { SignupComponent } from '../ares-users/components/signup/signup.component';
export { UsersComponent } from '../ares-users/components/users/users.component';
export { ContentManagerComponent} from '../data_layer/components/content-manager/content-manager.component';
