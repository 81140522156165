<mat-card class="dashboard">
  <mat-card-title> Dashboard</mat-card-title>
  <mat-card-content>
    <h2>Backend User Stuff</h2>
    <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="center center">
      <!--<a mat-raised-button routerLink="/profile"> Profile </a>-->
      <button mat-raised-button routerLink="/profile">Profile</button>
      <a
        mat-raised-button
        routerLink="/users"
        *ngIf="checklevel('admin') | async"
      >
        List of Local Users
      </a>
    </div>

    <!-- <h2> Users Stuff </h2>
        <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="center center" *ngIf="checklevel('hospitality') | async">
            <a mat-raised-button routerLink="/userinfo"> List of Users </a>
        </div> -->

    <h2>Online Events Stuff</h2>
    <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="center center">
      <a
        mat-raised-button
        routerLink="/online"
        *ngIf="checklevel('events') | async"
        >Online Events</a
      >
    </div>
    <h2>Roadtrips Stuff</h2>
    <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="center center">
      <a
        mat-raised-button
        routerLink="/synchronicity"
        *ngIf="checklevel('synchronicity') | async"
      >
        Synchronicity
      </a>
      <a
        mat-raised-button
        routerLink="/djwar"
        *ngIf="checklevel('djwar') | async"
      >
        Djwar
      </a>
      <a
        mat-raised-button
        routerLink="/junoon"
        *ngIf="checklevel('junoon') | async"
      >
        Junoon
      </a>
      <a mat-raised-button routerLink="/rap" *ngIf="checklevel('rap') | async">
        Rap
      </a>
      <a
        mat-raised-button
        routerLink="/beatboxing"
        *ngIf="checklevel('beatboxing') | async"
        >Beatboxing</a
      >
      <a
        mat-raised-button
        routerLink="/comicon"
        *ngIf="checklevel('comicon') | async"
      >
        Comicon
      </a>
    </div>

    <h2>Online EDM Stuff</h2>
    <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="center center">
      <a
        mat-raised-button
        routerLink="/onlineedm"
        *ngIf="checklevel('hospitality') | async"
        >Online EDM</a
      >
    </div>

    <h2>Campus Ambassador Stuff</h2>
    <div
      fxLayout="row"
      fxLayoutWrap
      fxLayoutGap="10px"
      fxLayoutAlign="center center"
      *ngIf="checklevel('hospitality') | async"
    >
      <a mat-raised-button routerLink="/calist"> List of Campus Ambassadors </a>
      <a mat-raised-button routerLink="/ideas"> Verify Ideas </a>
    </div>

    <h2>Mainfest Stuff</h2>
    <div
      fxLayout="row wrap"
      fxLayoutGap="10px"
      fxLayoutAlign="center center"
      *ngIf="checklevel('hospitality') | async"
    >
      <a mat-raised-button routerLink="/mainusers">
        List of Registered Users
      </a>
      <!-- <a mat-raised-button routerLink="/payments"> Verify Payments </a> -->
      <a
        mat-raised-button
        href="https://fb.antaragni.in/ragni/assign"
        target="_blank"
        >Assign Ids</a
      >
    </div>

    <h2>Content Management</h2>
    <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="center center">
      <a mat-raised-button routerLink="/manage"> Contents Manager </a>
      <!-- <a mat-raised-button routerLink="/munusers" *ngIf="checklevel('mun')|async"> Mun Users </a> -->
      <a mat-raised-button routerLink="/associates"> Associates </a>
      <div
        *ngIf="checklevel('admin') | async"
        fxLayout="row wrap"
        fxLayoutGap="10px"
        fxLayoutAlign="center center"
      >
        <a mat-raised-button routerLink="/permissions">
          Contents permissions
        </a>
        <!--<button mat-raised-button routerLink="/payments"> payments </button>-->
        <!--<button mat-raised-button (click)="call2()"> custom2 </button>-->
      </div>
    </div>
  </mat-card-content>
</mat-card>
