import { Component, OnInit } from '@angular/core';

import { LoggedUserService } from '../../services';
import { LocalUser } from '../../models';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/internal/operators';

@Component({
  selector: 'app-dashboard-component',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  localUser: Observable<LocalUser>;
  users = [];

  constructor(
    private localUserService: LoggedUserService,
    private afs: AngularFirestore,
    private http: HttpClient
  ) {}

  checklevel(str: string) {
    return this.localUserService.checkLevel(str);
  }

  ngOnInit(): void {
    this.localUser = this.localUserService.currentUser;
  }
  // bY8AntyCQHOfKCLfbTmZWMSzgUB3
  callme() {
    this.http
      .get('/assets/users.json')
      .pipe(
        map((res: any) => {
          const obj = res.users
            .filter((val) => val.providerUserInfo.length)
            .map((val) => val.localId);
          for (let i = 1532; i < 1843; i++) {
            this.afs.firestore
              .doc(`fbusers/${obj[i]}`)
              .get()
              .then((docSnapshot) => {
                if (!docSnapshot.exists) {
                  this.users.push(obj[i]);
                  console.log(obj[i]);
                }
              });
          }
          return this.users;
        })
      )
      .subscribe((res) => {
        // console.log(res);
      });
  }

  call2() {
    this.afs.doc('config/users').set({ users: this.users });
  }
}
