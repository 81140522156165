<button mat-raised-button (click)="excel()">Download Excel</button>
<div class="header">
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter by Anything" />
    </mat-form-field>
</div>
<mat-table #table [dataSource]="dataSource">
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="city">
        <mat-header-cell *matHeaderCellDef> City </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.personal.city }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="mobile">
        <mat-header-cell *matHeaderCellDef> Mobile </mat-header-cell>
        <mat-cell *matCellDef="let element">
            {{ element.personal.phoneNumber }}, {{ element.personal.whatsAppNumber }}
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.email }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="gender">
        <mat-header-cell *matHeaderCellDef> Gender </mat-header-cell>
        <mat-cell *matCellDef="let element">
            {{ element.personal.gender }}
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="college">
        <mat-header-cell *matHeaderCellDef> College </mat-header-cell>
        <mat-cell *matCellDef="let element">
            {{ element.personal.college }}
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="referred">
        <mat-header-cell *matHeaderCellDef> Referred By </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.referredBy }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="team">
        <mat-header-cell *matHeaderCellDef> Team </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.team }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="events">
        <mat-header-cell *matHeaderCellDef> Events </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.comps }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="social">
        <mat-header-cell *matHeaderCellDef>Social Media</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.facebook }}, {{element.insta}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="sno">
        <mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
        <mat-cell *matCellDef="let i = index"> {{ i + 1 }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="creator">
        <mat-header-cell *matHeaderCellDef> Content Creator ? </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.contentCreater }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="created">
        <mat-header-cell *matHeaderCellDef> UID </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.createKey }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="subs">
        <mat-header-cell *matHeaderCellDef>Submissions</mat-header-cell>
        <mat-header-cell *matCellDef="let element">
            <div *ngIf="element.links && element.links.length">
                <button mat-raised-button (click)="view(element.links)">
                    View Submissions
                </button>
            </div>
            <div *ngIf="!(element.links && element.links.length)">
                <p>No Submissions</p>
            </div>
        </mat-header-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="[
      'sno',
      'name',
      'city',
      'mobile',
      'email',
      'gender',
      'college',
      'referred',
      'team',
      'events',
      'subs',
      'creator',
      'created'
    ]"></mat-header-row>
    <mat-row *matRowDef="
      let row;
      columns: [
        'sno',
        'name',
        'city',
        'mobile',
        'email',
        'gender',
        'college',
        'referred',
        'team',
        'events',
        'subs',
        'creator',
        'created'
      ]
    "></mat-row>
</mat-table>

<mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50]" [showFirstLastButtons]="true">
</mat-paginator>