import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "@utils";
import { first, map } from "rxjs/internal/operators";

@Component({
  selector: "app-ideas",
  templateUrl: "./ideas.component.html",
  styleUrls: ["./ideas.component.css"],
})
export class IdeasComponent implements OnInit {
  Ideadata;
  toDelete = [];
  subjects;

  constructor(private afs: AngularFirestore, private dialogRef: MatDialog) {
    this.setData("name");
  }

  setData(filter: string) {
    const source = this.afs
      .collection("ideas22", (ref) => ref.orderBy(filter))
      .snapshotChanges();
    this.Ideadata = source.pipe(
      map((res) =>
        res.map((val) => {
          return {
            ...(val.payload.doc.data() as object),
            id: val.payload.doc.id,
          };
        })
      )
    );
    // tap((resp: Array<any>) => resp.map((res) => {
    //     if (res.idea.subject === '') {
    //       this.toDelete.push(res.id)
    //     }
    //   })
    // ))
  }

  seeChecked() {
    // console.log(this.toDelete);
    // for (let i = 0; i < this.toDelete.length; i++) {
    //   this.afs.doc(`ideas/${this.toDelete[i]}`).delete().catch(err => console.log(err));
    // }
    this.setData("status");
  }

  OpenDialog(sub: string, desc: string, id: string, uid: string) {
    const dialogRef = this.dialogRef.open(ConfirmDialogComponent, {
      data: `<h4>${sub}</h4><p>${desc}</p>`,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.afs.doc(`ideas22/${id}`).update({ status: 1 });
        const userref = this.afs.doc(`fbusers/${uid}`);
        userref
          .valueChanges()
          .pipe(first())
          .subscribe((user: any) =>
            userref.update({
              "campus.ideaPoints": user.campus.ideaPoints + 5,
              "campus.totalPoints": user.campus.totalPoints + 5,
            })
          );
      } else {
        this.afs.doc(`ideas22/${id}`).update({ status: 0 });
      }
      console.log(result);
    });
  }

  ngOnInit() {}
}
