import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { LoggedUserService } from '../../services';
import { Funcs } from 'app/utility/functions';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-navbar-component',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent {
  constructor(
    public loginService: LoggedUserService,
    private router: Router,
    private functions: Funcs,
    private location: Location,
    private afs: AngularFirestore
  ) {}

  logout = () => {
    this.loginService
      .logout()
      .then(() => this.router.navigate(['login']))
      .catch((err) => this.functions.handleError(err.message));
  };

  goBack() {
    this.location.back();
  }
}
