import {Component, OnInit, ViewChild} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import { MatTableDataSource } from '@angular/material/table';
import { JsontocsvService } from '../../../utility/services/jsontocsv.service'
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-djwar',
  templateUrl: './djwar.component.html',
  styleUrls: ['./djwar.component.css']
})
export class DjwarComponent implements OnInit {

  dataSource;
  data;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  // headings of material table, should be same order as schema
  fields = ['name', 'city', 'email', 'phone', 'songs', 'fb', 'other', 'createdAt'];

  // fieldnames fetched from firebase, comma separated will be merged while semi colon is separator
  schema = `
  name;
  city;
  email;
  phone,altphone;
  songs,moresongs;
  fblink;
  otherlinks;
  createdAt
  `;

  constructor(private afs: AngularFirestore, private j2c: JsontocsvService) {}

  ngOnInit() {
    this.afs.collection('djwar').valueChanges().subscribe(
      (res) => {
        this.data = res;
        this.dataSource = new MatTableDataSource(Object.keys(res).map(k => {
          console.log(res[k]);
          res[k]['createdAt'].seconds = new Date(res[k]['createdAt'].seconds*1000).toLocaleString();
           return res[k];}));
        this.dataSource.paginator = this.paginator;
      }
    );
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  excel() {
    this.j2c.excel(this.data);
  }
}
