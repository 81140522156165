<mat-card>
  <mat-card-title>Signup</mat-card-title>
  <form [formGroup]="form" (ngSubmit)="onSubmit()" fxLayout="column" fxLayoutAlign="center center">
    <mat-form-field fxFlex="33">
      <input matInput type="text" formControlName="name" id="name" name="name" placeholder="Username">
    </mat-form-field>
    <span id="name-help"
           class="text-warning"
           [hidden]="form.controls.name.valid || form.controls.name.pristine">
      Username should be atleast 5 letters
    </span>
    <div class="form-group" formGroupName="passwords" fxFlex="33" fxLayout="column" fxLayoutAlign="center center">
      <mat-form-field fxFlex="50">
        <input matInput type="password" formControlName="password" id="password" required name="password" placeholder="Password">
      </mat-form-field>
      <mat-form-field fxFlex="50">
        <input matInput type="password" class="form-control" formControlName="repeat" id="repeat" name="repeat" placeholder="Repeat Password">
      </mat-form-field>
      <span id="repeat-help"
             class="text-warning"
             [hidden]="form.controls.passwords.valid || form.controls.passwords.pristine">
        Passwords don't match
      </span>
    </div>
    <button mat-raised-button fxFlex="33" type="submit" class="btn btn-default" [disabled]="!form.valid || submitted">
      Submit
    </button>
  </form>
  <mat-card-footer>
    <p>
      Already have an account? <a routerLink="/login"> Sign In </a>
    </p>
  </mat-card-footer>
</mat-card>
