import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { JsontocsvService } from '../services/jsontocsv.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Funcs } from '../functions';

@Component({
  selector: 'app-material-table',
  templateUrl: './material-table.component.html',
  styleUrls: ['./material-table.component.css'],
})
export class MaterialTableComponent implements OnInit, OnChanges {
  @Input() fields: Array<string>;
  @Input() fieldsToDisplay: Array<string>;
  @Input() schema: string;
  @Input() dataSource;
  @Input() data;
  @Input() filters: Array<string>;

  str = ['', '', ''];
  fieldnames: Array<Array<string>>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private afs: AngularFirestore,
    private j2c: JsontocsvService,
    private fun: Funcs
  ) {}

  ngOnInit() {
    this.fieldnames = this.schema
      .replace(/[\t\n ]/g, '')
      .split(';')
      .map((val) => val.split(','));
  }

  ngOnChanges() {
    this.dataSource ? (this.dataSource.paginator = this.paginator) : '';
    if (!this.fieldsToDisplay) { this.fieldsToDisplay = this.fields; }
    this.dataSource ? (this.dataSource.sort = this.sort) : '';
  }

  excel() {
    this.j2c.excel(this.data);
  }

  togglepayment(uid, payment) {
    this.afs
      .doc(`fbusers/${uid}`)
      .update({ payment: !payment })
      .then(() => {
        this.fun.handleError('changed successfully');
      });
  }

  getVal(el, index) {
    return this.fieldnames[index].map((val) => el[val]).join(', ');
  }

  applyFilter(filVal: string, i: number) {
    filVal = filVal.trim();
    this.str[i] = filVal;
    let filterValue = this.str[0] + '$' + this.str[1] + '$' + this.str[2];
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  applylegacyFilter(filVal: string, i: number) {
    let filterValue = filVal.trim();
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  logger(r) {
    console.log(r);
  }
}
