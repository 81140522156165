import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatTableDataSource } from '@angular/material/table';
import { JsontocsvService } from '../../utility/services/jsontocsv.service';

export interface Munuser {
  name: string;
  college: string;
  mobile: string;
  whatsapp: string;
  city: string;
  email: string;
  post: string;
  fb: string;
}

@Component({
  selector: 'app-mun-users',
  templateUrl: './mun-users.component.html',
  styleUrls: ['./mun-users.component.css'],
})
export class MunUsersComponent implements OnInit {
  dataSource;
  data;
  fields = ['name', 'city', 'mobile', 'email', 'college', 'post', 'fb'];
  schema = `
  name;
  city;
  mobile,whatsapp;
  email;
  college;
  post;
  fb
  `;
  constructor(private afs: AngularFirestore, private j2c: JsontocsvService) {}

  ngOnInit() {
    this.afs
      .collection('fbusers')
      .valueChanges()
      .subscribe((res) => {
        this.dataSource = new MatTableDataSource(
          (this.data = Object.keys(res)
            .map((k) => {
              let temp: Munuser
                const r = res[k];
              if (r.munUpdate) {
                temp = {
                  name: r.name,
                  email: r.email.value,
                  post: r.registration.post,
                  college: r.personal.college,
                  city: r.personal.city,
                  mobile: r.personal.phoneNumber,
                  whatsapp: r.personal.whatsAppNumber,
                  fb: r.facebook.facebookLink,
                };
                return temp;
              }
              return null;
            })
            .filter((val) => val))
        );
      });
  }

  getEvents(evs): string {
    let str = '';
    for (const prop in evs) {
      if (evs[prop]) {
        str = str + ' ' + prop.toString();
      }
    }
    return str;
  }

  excel() {
    this.j2c.excel(this.data);
  }
}
