import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { catchError, first, take } from 'rxjs/internal/operators';
import { Funcs } from '@utils';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { JsontocsvService } from '../../../utility/services/jsontocsv.service';
import { EditUserDialogComponent } from '../../edit-user-dialog';

export interface FBPageResponse {
  data: Array<{
    id: string;
    message: string;
    full_picture: string;
    link: string;
    permalink_url: string;
  }>;
}

@Component({
  selector: 'app-calist',
  templateUrl: './calist.component.html',
  styleUrls: ['./calist.component.css'],
})
export class CalistComponent implements OnInit {
  dataSource;
  data;

  constructor(
    private http: HttpClient,
    private afs: AngularFirestore,
    private fun: Funcs,
    private j2c: JsontocsvService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.afs
      .collection('fbusers')
      .valueChanges()
      .subscribe((res) => {
        
        this.data = Object.keys(res)
          .map((k) => {
            return {uid: k,...res[k]};
          })
          .filter((val) => (val.campus ? val.campus.isAmbassador : false))
          .map((val) => {
            const temp = {
              createdAt: val.createdAt,
              uid: val.uid,
              personal: {
                city: val.personal.city,
                college: val.personal.college,
                phoneNumber: val.personal.phoneNumber,
                whatsAppNumber: val.personal.whatsAppNumber,
              },
              facebook: val.facebook,
              name: val.name,
              email: val.email,
              campus: {
                totalPoints: val.campus.totalPoints,
                ideaPoints: val.campus.ideaPoints,
                likes: val.campus.likes,
                shares: val.campus.shares,
                otherPoints: val.campus.otherPoints,
              },
            };
            return temp;
          });
          this.data.sort(function (a, b) {
            if(a.createdAt) {
              return -1;
            }
            else if(b.createdAt) {
              return 1;
            }
            return a.createdAt - b.createdAt;
          });
        this.dataSource = new MatTableDataSource(this.data);
      });
  }

  getFbLink(index: number) {
    // const fbID = 100003147102771; // Default fbID
    console.log(index);
    // const fbID = this.data[index].facebook.facebookID;
    const url = `https://www.facebook.com/profile.php?id=${index}`;
    return url;
  }

  fetchPosts() {
    this.afs
      .collection('fbpage', (ref) =>
        ref.orderBy('created_time', 'desc').limit(1)
      )
      .valueChanges()
      .pipe(
        take(1),
        catchError((err) => this.fun.handleError(err.message))
      )
      .subscribe((val: any) => {
        this.http
          .get(
            'https://graph.facebook.com/antaragni.iitk/posts?' +
              'since=1530403200&' +
              'limit=100&' +
              'fields=created_time,message,full_picture,link,permalink_url&' +
              'access_token=EAAZAkpZCZCEb7gBAL0YCLRFIGfvsfTDiBmZBO9sD8TIvTGZCP0wp4xpJFwpZCrHiz4aAqDMrkgnOA1aUmiJWyJ6M8tdQmGyDf04J6YzagFzW45aNZBW6TT7xwAa4VcDKtjeZAkq0YEl1G4RQzMIZCC8ef5v4RofVGFQ8A9aQSrm8MxgZDZD'
          )
          .pipe(catchError((err) => this.fun.handleError(err.message)))
          .subscribe((res: FBPageResponse) => {
            for (const post of res.data) {
              // if (post.link.split('/')[4] !== 'antaragni.iitk') {
              //   return
              // }
              if (post.id === val[0].id) {
                return;
              }
              this.afs
                .doc(`fbpage/${post.id}`)
                .set(post)
                .catch((err) => this.fun.handleError(err.message));
            }
          });
      });
  }

  getDate(value: number) {
    const d = new Date(value);
    console.log(new Date(value).getDate(),value);
    return d.toLocaleString(undefined, {timeZone: 'Asia/Kolkata'});
 
  }

  deleteUser(id: string) {
    console.log("delete requested",id);
    this.afs
      .doc(`/fbusers/${id}`)
      .delete()
      .then((val) => {
        console.log("success",val);
      })
      .catch((err) =>
        this.fun.handleError('can\'t delete user: ' + err.message)
      );
  }

  openDialog(userData: any) {
    const dialogRef = this.dialog.open(EditUserDialogComponent, {
      data: userData,
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  makeUpdatefalse() {
    this.afs
      .collection('/fbusers')
      .valueChanges()
      .pipe(first())
      .subscribe((res: Array<any>) => {
        for (let i = 0; i < res.length; i++) {
          // this.afs.doc(`/fbbackup/${res[i].uid}`).set(res[i]);
          this.afs.doc(`/fbusers/${res[i].uid}`).update({ firstUpdate: false });
        }
      });
  }

  excel() {
    console.log(this.data);
    this.j2c.excel(this.data);
  }
}
