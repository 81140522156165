import {LoginComponent} from './login';
import {SignupComponent} from './signup';
import {DashboardComponent} from './dashboard';
import {ProfileComponent} from './profile';
import {NavbarComponent} from './navbar';
import {UsersComponent} from './users';
import {ContentManagerComponent} from '../../data_layer/components/content-manager';

export const components: any[] = [
  LoginComponent,
  DashboardComponent,
  ProfileComponent,
  NavbarComponent,
  SignupComponent,
  UsersComponent,
  ContentManagerComponent
];

export * from './navbar';
export * from './signup';
export * from './dashboard';
export * from './profile';
export * from './login';
export * from './users';
export * from '../../data_layer/components/content-manager';

