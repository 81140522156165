<button mat-raised-button (click)="excel()">Download Excel</button>
<div class="header">
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter by Anything" />
    </mat-form-field>
</div>
<mat-table #table [dataSource]="dataSource">
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="age">
        <mat-header-cell *matHeaderCellDef> Age </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.age }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="college">
        <mat-header-cell *matHeaderCellDef> College </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.college }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.email }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="phone">
        <mat-header-cell *matHeaderCellDef> Phone </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.phone }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.type }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="source">
        <mat-header-cell *matHeaderCellDef> Source </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.source }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="caid">
        <mat-header-cell *matHeaderCellDef> CA ID </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.caid }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="sno">
        <mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
        <mat-cell *matCellDef="let i = index"> {{ i + 1 }} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="[
      'sno',
      'name',
      'college',
      'email',
      'phone',
      'age',
      'type',
      'source',
      'caid'
    ]"></mat-header-row>
    <mat-row *matRowDef="
      let row;
      columns: [
        'sno',
        'name',
        'college',
        'email',
        'phone',
        'age',
        'type',
        'source',
        'caid'
      ]
    "></mat-row>
</mat-table>

<mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50]" [showFirstLastButtons]="true">
</mat-paginator>