import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  templateUrl: './submissions.component.html',
  styleUrls: ['./submissions.component.css']
})
export class SubmissionsComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  // dataArray = [];

  ngOnInit(): void {
    console.log('data', this.data);
    // if(this.data) {
    //   this.dataArray = this.data.split("\n");
    //   this.dataArray = this.dataArray.map((d) => {
    //     let kv = d.split(':');
    //     if(kv && kv.length > 1) {
    //       return {
    //         key: kv[0],
    //         value: kv[1]
    //       };
    //     }
    //     return {
    //       key: d
    //     };
    //   });
    // }
  }

}
