import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { JsontocsvService } from '../../../utility/services/jsontocsv.service'
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-junoon',
  templateUrl: './junoon.component.html',
  styleUrls: ['./junoon.component.css']
})


export class JunoonComponent implements OnInit {

  dataSource;
  data;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(private afs: AngularFirestore, private j2c: JsontocsvService) { }
  lowercaseKeys(obj) {
    return Object.keys(obj).reduce((accumulator, key) => {
      accumulator[key.toLowerCase()] = obj[key];
      return accumulator;
    }, {});
  }
  ngOnInit() {
    
    this.afs.collection('junoon').valueChanges().subscribe(
      (res) => {
        this.data = res;
        this.dataSource = new MatTableDataSource(Object.keys(res).map(k => {
          console.log(this.lowercaseKeys(res[k]));
          
          
          res[k]['createdAt'].seconds = new Date(res[k]['createdAt'].seconds*1000).toLocaleString();
           return this.lowercaseKeys(res[k])}));
        this.dataSource.paginator = this.paginator;
      }
    );
  }
  

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  excel() {
    this.j2c.excel(this.data);
  }

}

